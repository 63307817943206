import React from 'react'
import Layout from '../components/layout-v2';


const Page = () => (

  <Layout title="Terms of Service">
    <section className="section-terms-of-service">
      <div className="content_fullwidth">
        <div className="one_full">
          <p>These terms and conditions state important requirements regarding your use of DD Zone website and DD Zone computer support service and your relationship with DD Zone support. It will apply to all your service plans from us, including all your existing plans. Your acceptance of these terms &amp; conditions will be implied by the use of the DD Zone service. </p>
        </div>
        <div className="clearfix mar_top3"></div>
        <div className="one_full">
          <h4>Agreement</h4>
          <p>These terms and conditions, together with each accepted plan order submitted by you, any additional terms mentioned for your plan order and the privacy policy, state the entire agreement between you and DD Zone. The plan order will form the part of the “agreement” only if the same has been acknowledged by DD Zone in writing or by an e-mail. You must agree to the terms and conditions in order to be eligible to use the DD Zone, or obtain services.  </p>
        </div>
        <div className="clearfix mar_top3"></div>
        <div className="one_full">
          <h4>Services and DD Zone </h4>
          <p>All references to “services” refer to any DD Zone service delivered through DD Zone technologies, under the plan that you enter into with DD Zone through use of the DD Zone website located at https://ddzone.xyz or by calling the DD Zone phone number mentioned on the DD Zone website. These terms of use govern all plans available through the DD Zone website, and any use of the DD Zone. In the event of any conflict these terms of use control any valid plan order form that you submit requesting services. </p>
        </div>

        <div className="clearfix mar_top3"></div>
        <div className="one_full">
          <h4>Submission of plan orders, service plans </h4>
          <p>You may order services by submitting plan orders through the DD Zone or by calling DD Zone support. Once DD Zone accepts the plan order submitted by you, then you will receive an email from DD Zone at the email address that you provide or have provided to DD Zone as part of the registration process for the services. DD Zone is not responsible for rendering services in connection with any plan order that it has not accepted. Upon acceptance by DD Zone of a plan order, you will have a service plan. </p>
        </div>

        <div className="clearfix mar_top3"></div>
        <div className="one_full">
          <h4>Linking</h4>
          <p>You may not create hyperlinks to any portion of the DD Zone, nor any materials or software posted therein. </p>
        </div>
        <div className="clearfix mar_top3"></div>
        <div className="one_full">
          <h4>User Responsibility</h4>
          <p>In connection with obtaining services, you agree that you will: </p>
          <div className="clearfix mar_top2"></div>
          <ul >
            <li>1. Co-operate with the DD Zone technician: we will use commercially reasonable efforts to provide the support to you. Our experience shows that most issues can be corrected as a result of close cooperation between you and the technician. Please listen carefully to the technician and follow the technician’s instructions. You must confirm that the following conditions are true:
              <div className="clearfix mar_top1"></div>
              <ul>
                <li>(a)The situation giving rise to the question is, reproducible on a single system, i.e., one central processing unit with its workstations and other peripherals. </li>
                <li>(b)You must have knowledge regarding the hardware system, any software involved, and in the facts and circumstances surrounding the incident. </li>
              </ul>
            </li>
            <div className="clearfix mar_top2"></div>
            <li>2. The full system, including software and hardware, is available to you and accessible by you without limit during any telephone discussions with DD Zone support personnel. Software/ data backup: you understand and agree that DD Zone shall under no circumstance be responsible for any lost or corrupted software or data. DD Zone strongly recommends that you at all times maintain a complete data backup and disaster recovery plan. </li>
            <div className="clearfix mar_top2"></div>
            <li>3. Account, password, and security: for you to submit a plan order, you must complete the registration process by providing us with current, complete and accurate information as prompted by and required under the applicable registration form. You also will choose a password and a user name. You are solely and entirely responsible for maintaining the confidentiality of your password and account. Furthermore, you are solely and entirely responsible for any and all activities that occur under your account. You agree to notify DD Zone immediately of any unauthorized use of your account or any other breach of security. DD Zone will not be liable for any loss that you may incur as a result of someone else using your password or account, either with or without your knowledge. However, you could be held liable for losses incurred by DD Zone or another party due to someone else using your account or password. You may not use anyone else’s account at any time, without the permission of the account holder.</li>
          </ul>
        </div>
        <div className="clearfix mar_top3"></div>
        <div className="one_full">
          <h4>Availability of services and materials under force majeure circumstances </h4>
          <p>You hereby acknowledge that circumstances outside of DD Zone reasonable control (e.g., acts of god, a large scale outbreak of a new computer virus, strikes, riots, wars, other military action, civil disorder, acts of terrorism, fires, floods, hacking, vandalism, sabotage, acts of third parties, or the like) may cause significant delays in DD Zone ability to schedule a support session. You hereby release DD Zone from any and all liability, and agree that DD Zone shall not be liable to you or any third party for any direct or indirect damages whatsoever, resulting from such delays. DD Zone or its suppliers may, at any time, without notice or liability, restrict the use of the service or limit its time of availability in order to perform maintenance activities and to maintain session control.</p>
        </div>
        <div className="clearfix mar_top3"></div>
      </div>
    </section>
  </Layout>
);

export default Page;

